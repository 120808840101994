import React from 'react'
import Address from './Address';

const Update = () => {
  return (
    <div>
        <Address />
    </div>
  )
}

export default Update;